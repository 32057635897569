import React from 'react';
import { Link } from 'gatsby';
import { Icon } from '@iconify/react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/navigation';
import FullImage from '../components/full-image';
import Dots from '../components/dots';

/**
 * Page
 */
const LeistungenPage = () => {
	return (
		<Layout image="leistungen">
			<SEO title="" />
			<Menu selected="leistungen" />
			<Dots />
			<div className="anchor">
				<div id="cd" />
			</div>
			<div className="page-width align-center spacing-normal spacing-huge-top">
				<Icon icon="vb:cd" height="auto" className="image-blue" />
				<p className="text-subheadline text-subheadline--bold">
					Corporate design / relaunch
				</p>
				<p>
					Ein erfolgreiches Corporate Design ist die gelungene
					Umsetzung Ihrer Unternehmensphilosophie. Es hat die Aufgabe
					einen ersten Eindruck von Ihr Unternehmen zu vermitteln,
					Ihrer unverwechselbaren Identität gerecht zu werden und
					medienübergreifend zu funktionieren.
				</p>
				<p>
					Die Strahlkraft Ihres Aussenauftritts basiert auf einer
					klaren Marktstrategie. In Kooperation mit dem
					Strategieberaterteam von{' '}
					<a href="https://www.gral-beraterteam.de" target="_blank">
						gral - Zeit für neue Perspektiven
					</a>{' '}
					entwickeln wir Ihre individuelle Strategie, auf deren Basis
					das visuelle Konzept noch durchdachter und nachhaltiger
					gestaltet wird.
				</p>

				<div className="split">
					<div>
						<Icon icon="vb:leistungen0" height="auto" />
						<p className="text-subheadline">GRÜNDER</p>
						<p>
							{/* Entwicklung und Definition der Unternehmensfarben-,
							Schriften-, Grafikelementen, Logo usw. Gestaltung
							eines einheitlichen Außenauftritts, der sich auf
							allen Print- und Online Medien durchzieht. */}
							Entwicklung und Definition der Unternehmensfarben,
							-schriften, Grafikelemente, Logo usw., Gestaltung
							eines einheitlichen Außenauftritts, der sich durch
							sämtliche Print- und Online-Medien zieht.
						</p>
					</div>
					<div>
						<Icon icon="vb:leistungen1" height="auto" />
						<p className="text-subheadline">
							BESTEHENDE UNTERNEHMEN
						</p>
						<p>
							Weiterentwicklung oder Modernisierung des
							bestehenden visuellen Konzeptes, wenn das Corporate
							Design nicht mehr zeitgemäß oder nicht mehr zu Ihrem
							Unternehmen passt.
						</p>
					</div>
				</div>

				<Dots />

				<p className="text-huge">
					IHR CORPORATE DESIGN SOLLTE DIE WERTE IHRES UNTERNEHMENS
					REPRÄSENTIEREN.
				</p>
			</div>

			<FullImage image="leistungen2" />

			<div className="anchor">
				<div id="dp" />
			</div>

			<div className="page-width align-center spacing-big spacing-huge-top">
				<Icon icon="vb:dp" height="auto" className="image-blue" />
				<p className="text-subheadline text-subheadline--bold">
					DIGITAL- &amp; PRINTDESIGN
				</p>
				<p>
					Ganz gleich, ob zur Nutzung im Online- oder Printbereich,
					entwickele ich die für Sie passenden Werbemittel. In
					Zusammenarbeit mit meinem zuverlässigen Partnernetzwerk
					biete ich Ihnen den kompletten Service. So erhalten Sie Ihre
					Drucksachen bequem nach Hause geliefert und Ihre neue
					Webseite wird professionell programmiert, ohne dass Sie
					einen zusätzlichen Aufwand haben. Ich kümmere mich um alles
					und bleibe Ihre Ansprechpartnerin.
				</p>

				<div className="split">
					<div>
						<Icon icon="vb:leistungen2" height="auto" />
						<p className="text-subheadline">PRINT</p>
						<p>
							Gestaltung und Druck der Geschäftsausstattung:
							Visitenkarten, Geschäftspapier, Flyer, Broschüren,
							Poster, Blöcke, Banner, Schilder und vieles mehr.
						</p>
					</div>
					<div>
						<Icon icon="vb:leistungen3" height="auto" />
						<p className="text-subheadline">ONLINE</p>
						<p>
							Gestaltung und Programmierung von Webseiten.
							Erstellung von Werbung für Social Media.
						</p>
					</div>
				</div>

				<Dots />
			</div>

			<div className="anchor">
				<div id="ma" />
			</div>

			<div className="page-width align-center spacing-big">
				<Icon icon="vb:ma" height="auto" className="image-blue" />
				<p className="text-subheadline text-subheadline--bold">
					MARKENAUFBAU
				</p>
				<p>
					In Zusammenarbeit mit der{' '}
					<a href="https://www.gral-beraterteam.de" target="_blank">
						gral Strategieberatung
					</a>{' '}
					biete ich Marketingworkshops zum Aufbau einer starken Marke
					an. Ein Jahresplan wird entwickelt, der sämtliche
					Marketingmaßnahmen und Werbemittel und -kanäle aufführt. So
					wird die Marke kontinuierlich aufgebaut und Ihr Unternehmen
					erfolgreich nach Außen präsentiert.
				</p>

				<div className="split">
					<div>
						<Icon icon="vb:leistungen4" height="auto" />
						<p className="text-subheadline">
							MONATLICHE / JÄHRLICHE STRATEGIE
						</p>
						<p>
							Regelmäßige Gestaltung von Anzeigen für Print und
							Online Bereich (Facebook & Instagram).
							Kontinuierlicher Aufbau von
							Arbeitgeberattraktivität.
						</p>
					</div>
					<div>
						<Icon icon="vb:leistungen5" height="auto" />
						<p className="text-subheadline">LAUFENDE KAMPAGNEN</p>
						<p>Jahrestage, Eröffnung, Besondere Anlässe</p>
					</div>
				</div>
			</div>

			<div className="leistungen-footer">
				<FullImage image="fassade" />
				<div className="contact">
					<Link to="/kontakt/" className="button">
						<Icon icon="vb:send" />
						Anfrage Senden
					</Link>
				</div>
			</div>

			<div className="page-width align-center spacing-normal big-logo">
				<Icon icon="vb:big-logo" height="auto" />
				<Icon icon="vb:big-logo-text" height="auto" />
			</div>

			<div className="page-width contact-buttons">
				<a
					href="https://www.facebook.com/vbgrafikdesign/"
					target="_blank"
					rel="noreferrer"
				>
					<Icon icon="vb:facebook-button" />
				</a>
				<a
					href="https://www.instagram.com/vb_grafikdesign/?hl=de"
					target="_blank"
					rel="noreferrer"
				>
					<Icon icon="vb:instagram-button" />
				</a>
			</div>
		</Layout>
	);
};

export default LeistungenPage;
